<template>
	<div class="navbar bg-white p-2 sticky z-50 top-0 left-0 flex shadow" v-if="singleCourse.length>0">
		<div class="navbar-start">
			<div class="w-40">
				<div class="lg:flex">
					<span class="text-lg font-bold cursor-pointer">
						<img src="@/assets/dynematrix.svg" alt="" class="w-96 h-8" />
					</span>
				</div>
			</div>
		</div>
		<div class="navbar-center">
			<div class="items-center justify-center lg:flex">
				<h1>{{ singleCourse[0].courseName }}</h1>
			</div>
		</div>
		<div class="navbar-end">
			<button class="btnprimary" @click="onExit()">EXIT</button>
		</div>
	</div>
	<div class="h-full w-full overflow-auto ">

		<div class="flex items-center flex-col p-3" >
		<!-- <h1>Course Name</h1> -->
		<h1 v-if="showText">Test</h1>
		<h1 v-else>Test Results</h1>
		</div>
		<div class="maincontainer flex flex-col items-center p-2">
			<div>
				<div class="card p-8 relative font-opensans" :class="{ 'flex items-center justify-center': scorecard && !hideQuestions }">
					<div class="startSection p-4" v-if="!hideQuestions && !scorecard">
						<h3 class="font-semibold">Read the question and answer carefully.</h3>

						<div class="startpoints py-6 px-8">
							<ul class="list-disc">
								<li class="text-base">Total number of questions are 20 and all questions are mandatory.</li>
								<li class="text-base">You should acheive 80% to pass the test.</li>
								<li class="text-base">The course will not be completed without a pass result.</li>
								<li class="text-base">Make sure that you clicked on submit button to successfully complete the test.</li>
								<li class="text-base">Try to submit the answers in 30 minutes.</li>
							</ul>
						</div>
						<div class="absolute bottom-20 right-8 cursor-pointer">
							<button @click="onNext()" class="btnprimary">Start</button>
						</div>
					</div>

					<div class="questions" v-if="hideQuestions">
						<div class="questionblock">
							<p class="titleheader text-lg mt-2">Q{{ counter + 1 }}. {{ question.question }}</p>
						</div>

						<div class="optionsblock">
							<!-- <label class="inputGroup flex mt-3 p-2" v-for="(option, index) in question.options" :key="index" >
								<input type="radio" :value="option.text" :id="option.text + index" :checked="option.alreadySelected" @change="checkedoptions(index, question, $event)" v-if="question.questionaireType == 'RadioQuestionType'" v-model="question.picked" />
								<input type="checkbox"  :value="option.text" :id="option.text" :checked="option.alreadySelectedValue" @change="checkedoptions(index, question, $event)" v-model="question.checked" v-else />
								<div :for="option.text" class="question ml-2 "  >{{ option.text }}</div>
							</label> -->

							<div class="inputGroup cursor-pointer  mt-3 rounded-md font-normal flex justify-center items-center text-base" v-for="(option, index) in question.options" :key="index">
								<input type="radio" :value="option.text" :id="option.text"  @change="checkedoptions(index, question, $event)" v-if="question.questionaireType == 'RadioQuestionType'" v-model="question.picked" class="cursor-pointer radio" />
								<input type="checkbox" :value="option.text" :id="option.text"  @change="checkedoptions(index, question, $event)" v-model="question.checked" class="cursor-pointer checkbox" v-else />
								<label :for="option.text" class="question text-sm ml-2 w-full text-left">{{ option.text }}</label>
							</div>
							<div class="absolute bottom-20 right-8">
								<div class="flex flex-row-reverse mt-2 cursor-pointer">
									<button class="btnprimary" @click="onNext()" v-if="totalQuestions.length - 1 != counter">Next</button>
									<button class="btnprimary" @click="onSubmit()" v-if="totalQuestions.length - 1 == counter">Submit</button>
									<button class="btnprimary mr-2" @click="onPrevious()" v-if="counter != 0">Previous</button>
								</div>
							</div>
						</div>
					</div>
					<div class="scorecard flex justify-center items-center flex-col" v-if="scorecard && !hideQuestions && !error">
						<img src="@/assets/pass.png" alt="" class="h-16" />

						<p class="mt-2 text-green text-2xl font-semibold">Pass</p>
						<!-- <p class="text-sm">You have succesfully completed the exam</p> -->
						<p class="mt-6 text-xl">Total Score :{{ testResult.pass }}/{{ testResult.total }}</p>
					</div>

					<div class="scorecard flex justify-center items-center flex-col" v-if="scorecard && !hideQuestions && error">
						<img src="@/assets/fail.png" alt="" class="h-16" />

						<p class="mt-2 text-error text-2xl font-semibold">Failed</p>
						<!-- <p class="text-sm">You have not completed the exam</p> -->
						<p class="mt-6 text-xl">Total Score :{{ testResult.pass }}/{{ testResult.total }}</p>
					</div>

					<div class="flex items-center justify-between h-screen" v-if="scorecard">
						<button class="btnprimary" @click="modules('modules')">Course Home</button>
						<button  class="btnprimary ml-8" @click="modules('startExam')" >Retake Test</button>		
					</div>
					<div v-if="scorecard && feedbackPage">
						<div class="opacity-40 fixed inset-0 z-50 bg-black"></div>
						<div class="fixed inset-0 flex items-center z-index-box justify-center">
							<div class="border-0 rounded overflow-y-auto overflow-x-hidden fixed flex flex-col w-full max-w-xl bg-white ratingBox">
								<div class="flex items-center justify-between py-4 px-2 bg-primary">
									<h1 class="text-white pl-2">Feedback</h1>
									<button class="float-right text-3xl" @click="(feedbackPage = false)">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M3.5868 3.60317C3.23149 3.79159 3.03768 4.10383 3.01077 4.52375C2.97308 5.14285 2.93001 5.08901 5.09417 7.26394L7.05915 9.23968L5.14263 11.167C3.08075 13.2288 3 13.3257 3 13.8426C3 14.494 3.51143 15 4.16822 15C4.67426 14.9946 4.78193 14.9085 6.83842 12.8574L8.76033 10.9355L10.6876 12.8574C11.7428 13.9125 12.6957 14.817 12.7979 14.8708C12.9218 14.9354 13.1048 14.9677 13.3363 14.9731C14.0308 14.9731 14.4937 14.5101 14.4937 13.8156C14.4883 13.5841 14.456 13.4011 14.3914 13.2773C14.3376 13.175 13.4332 12.2221 12.378 11.167L10.4615 9.23968L12.4265 7.26394C14.5906 5.08901 14.5476 5.14285 14.5099 4.52375C14.483 4.10383 14.2892 3.79159 13.9339 3.60317C13.6485 3.45243 13.1694 3.43628 12.8625 3.56549C12.728 3.61932 12.0173 4.29226 10.7145 5.59506L8.76033 7.5385L6.80612 5.59506C5.50332 4.29226 4.7927 3.61932 4.65811 3.56549C4.35125 3.43628 3.87212 3.45243 3.5868 3.60317Z" fill="white"/>
										</svg>
									</button>
								</div>
								<div class="border-b border-solid border-lightgrey"></div>
								<h2 class="ml-3 mt-3 text-xs">How would you rate your experience ?</h2>
								<div class="mt-3 ml-3 flex">
									<div class="mr-3" v-for="rating in reviewValues" :key="rating">
										<div class="flex justify-center cursor-pointer" @click="getRating(rating.value)" @mouseover="mouseOnStar(rating.value)" @mouseleave="mouseleftOnStar">
											<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M16 4.58789L18.833 13.3069H28L20.584 18.6939L23.416 27.4129L16 22.0229L8.583 27.4119L11.416 18.6929L4 13.3069H13.167L16 4.58789Z" :fill="(rating.value <= currentRatingValue) || (rating.value <= clickedRatingValue) ? '#152A43' : '#E9E9E9'"/>
											</svg>
										</div>
										<p v-if="(rating.value == currentRatingValue) || (rating.value == clickedRatingValue)" class="reviewName mt-2">{{rating.name}}</p>
										<p v-else class="reviewName h-6"></p>
									</div>
								</div>
								<h2 class="ml-3 mt-2 text-xs">How can we improve ?</h2>
								<textarea
									placeholder="Tell us more...."
									class="inputboxstyling mt-2 ml-3 textbox"
									v-model="review.comments"
								></textarea>
								<div class="flex justify-center mt-3 mb-2">
									<button @click="submitRating(review)" :class="review.rating == ''?'btndisabled pointer-events-none':'btnprimary'">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>

	</div>
</template>
<style scoped>
.card {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 4px;
}
.navbar{
	min-height: 2.5rem;
	height:2.5rem
}
.z-index-box {
	z-index: 99999;
	margin-top: 70px;
}
.ratingBox{
	height: 276px;
}
.reviewName{
	color: #000000;
	text-align: center;
	font-family: Open Sans;
	font-size: 8px;
	font-style: italic;
	font-weight: 600;
	line-height: normal;
	width: 39px;
}
.textbox{
	width: 520px;
	height: 50px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { toast } from '../../main';
export default defineComponent({
	data(): any {
		return {
			totalQuestions: [],
			questionaire: [],
			dataArray: [],
			counter: -1,
			checkedoptions1: [],
			question: [],
			next: 0,
			questionBlock: false,
			showText:true,
			picked: [],
			hideQuestions: false,
			isSelectedValue: [],
			selectedAnswerIndexes: [],
			selectedAnswerText: [],
			sampleData: [],
			singleCourse: [],
			sampletruearray: [],
			checkedarray: [],
			scorecard: false,
			feedbackPage:false,
			testResult: [],
			error: false,
			allModules:[],
			allActiveModuleIds:[],
			review:{
				rating:'',
				comments:''
			},
			currentRatingValue:0,
			clickedRatingValue:0,
			reviewValues:[
				{name:'Very Bad',value:1},
				{name:'Bad',value:2},
				{name:'Satisfied',value:3},
				{name:'Good',value:4},
				{name:'Very Good',value:5},
			]
		};
	},
	computed:{
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		idExists(questionId: any) {
			return this.dataArray.some(function (el: any) {
				return el._id === questionId;
			});
		},
		onExit() {
			window.close();
		},
		getRating(value:any){
			console.log('rating given',value)
			this.clickedRatingValue = value;
			this.review.rating = value;
		},
		mouseOnStar(value:any){
			console.log('rating moused on',value)
			this.currentRatingValue = value;
		},
		mouseleftOnStar(value:any){
			this.currentRatingValue = 0;
		},
		modules(type: any) {
			 if (type == 'startExam') {
				location.reload();
				// this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
			}else {
				this.$router.push({ name: 'course-modules', params: { courseId: this.$route.params.courseId } })

			}
		},

		getRandom(arr: any, n: any) {
			var result;

			result = new Array(n);
			var len = arr.length;
			var taken = new Array(len);

			while (n--) {
				var x = Math.floor(Math.random() * len);
				result[n] = arr[x in taken ? taken[x] : x];
				taken[x] = --len in taken ? taken[len] : len;
			}

			return result;
		},

		onsavedData() {
			this.allQuestions = [];

			for (var i = 0; i < this.singleCourse[0].modules.length; i++) {
				let Questionairearray = 'questionaire' in this.singleCourse[0].modules[i];
				if (Questionairearray) {
					for (var j = 0; j < this.singleCourse[0].modules[i].questionaire.length; j++) {
						this.allQuestions.push(this.singleCourse[0].modules[i].questionaire[j]);
					}
				}
			}
			if (this.allQuestions.length <= 20) {
				this.sampleData = this.allQuestions;
			} else {
				var finaldata = this.getRandom(this.allQuestions, 20);
				this.sampleData = finaldata;
			}
		},

		remove(arr: any, item: any) {
			var index = arr.indexOf(item);
			return [
				// part of the array before the given item
				...arr.slice(0, index),

				// part of the array after the given item
				...arr.slice(index + 1)
			];
		},
	
		async submitRating(review:any){
			console.log('reviewwww',review)
			const now = new Date();
			review.createdAt = now;
			review.createdBy = this.userInfo._id;
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/user/${this.userInfo._id}/course/${this.$route.params.courseId}/rating`, review)
				.then((response: any) => {
					toast.info('Feedback submitted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.feedbackPage = false;
				})
				.catch((error: any) => {
					toast.error('error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async onSubmit() {
			this.scorecard = true;
			this.feedbackPage = true;
			this.showText=false,
			(this.hideQuestions = false), console.log(this.dataArray);
			let totalquestionandselectedvalues={
				totalQuestions:this.totalQuestions.length,
				dataArray:this.dataArray
			}
			await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/${this.userInfo.organisationId}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/questionairescreens/result`, totalquestionandselectedvalues).then((res: any) => {
				this.testResult = res.data;
				var resultPercentage = Math.round((this.testResult.pass / this.testResult.total) * 100);
				if (resultPercentage < 80) {
					this.error = true;
				} else {
					this.error = false;
				}
				// alert(JSON.stringify({ totalQuestions: this.testResult.total, right: this.testResult.pass, wrong: this.testResult.fail }));
			});
		},
		checkedoptions(optionIndex: number, question: any, e: any) {
			var presentId = this.idExists(question._id);
			var index = this.dataArray.findIndex((obj: any) => obj._id == question._id);

			if (question.questionaireType == 'ChecBoxQuestionType') {
				if (e.target.checked) {
					question.selectedAnswerIndexes.push(optionIndex);
				} else {
					var finalOptionSelected = this.remove(question.selectedAnswerIndexes, optionIndex);
					question.selectedAnswerIndexes = finalOptionSelected;
				}
				if (presentId) {
					this.dataArray[index].vindex = question.selectedAnswerIndexes;
				} else {
					this.dataArray.push({ _id: question._id, vindex: question.selectedAnswerIndexes, questionType: question.questionaireType });
				}
			} else {
				var selectedOption = [];
				selectedOption.push(optionIndex);
				if (presentId) {
					this.dataArray[index].vindex = selectedOption;
				} else {
					this.dataArray.push({ _id: question._id, vindex: selectedOption, questionType: question.questionaireType });
				}
			}
		},
		onPrevious() {
			this.alreadySelected = [];
			this.counter = this.counter - 1;
			var questionIndex = this.totalQuestions[this.counter];
			var checkId = this.idExists(questionIndex._id);
			var optionsPropertyCheck = questionIndex.options;
			var dataindex = this.dataArray.findIndex((x: any) => x._id == questionIndex._id);
			if (questionIndex.questionaireType == 'ChecBoxQuestionType') {
				if (checkId) {
					optionsPropertyCheck.forEach((element: any) => {
						delete element.alreadySelectedValue;
					});
					var optionCheckedIndex = [];

					for (var i = 0; i < this.dataArray[dataindex].vindex.length; i++) {
						optionCheckedIndex = this.dataArray[dataindex].vindex[i];
						this.totalQuestions[this.counter].options[optionCheckedIndex].alreadySelectedValue = true;
					}
					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			} else {
				if (checkId) {
					var optionCheckedIndexs = this.dataArray[dataindex].vindex;
					this.totalQuestions[this.counter].options[optionCheckedIndexs].alreadySelected = true;
					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			}
		},

		onNext() {
			// window.open(`${window.location.origin}/org/course/61fb7c6185781a3180682623/test`, '_blank');
			this.alreadySelected = [];
			this.hideQuestions = true;
			this.counter = this.counter + 1;
			var questionId = this.totalQuestions[this.counter];
			var checkId = this.idExists(questionId._id);
			var optionsPropertyCheck = questionId.options;
			var dataindex = this.dataArray.findIndex((x: any) => x._id == questionId._id);
			if (questionId.questionaireType == 'ChecBoxQuestionType') {
				if (checkId) {
					optionsPropertyCheck.forEach((element: any) => {
						delete element.alreadySelectedValue;
					});
					var optionCheckedIndex = [];

					for (var i = 0; i < this.dataArray[dataindex].vindex.length; i++) {
						optionCheckedIndex = this.dataArray[dataindex].vindex[i];
						this.totalQuestions[this.counter].options[optionCheckedIndex].alreadySelectedValue = true;
					}

					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			} else {
				if (checkId) {
					optionsPropertyCheck.forEach((element: any) => {
						delete element.alreadySelected;
					});
					var optionCheckedIndexs = this.dataArray[dataindex].vindex;
					this.totalQuestions[this.counter].options[optionCheckedIndexs].alreadySelected = true;
					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			}
		},

		async getSingleCourse() {
			this.spinnerLoading = true;
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`).then((res: any) => {
				this.singleCourse = res.data;
			});
			await this.onsavedData();
		},
		async getAllQuestions() {
			await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/questionairescreens/getAll`, this.sampleData).then((res: any) => {
				// this.totalQuestions = res.data;
				res.data.map((question:any)=>{
					if(this.allActiveModuleIds.includes(question.moduleId)){
						this.totalQuestions.push(question)
					}
				})
				for (var i = 0; i < this.totalQuestions.length; i++) {
					if (this.totalQuestions[i].questionaireType == 'ChecBoxQuestionType') {
						this.totalQuestions[i].checked = [];
						this.totalQuestions[i].selectedAnswerIndexes = [];
					} else {
						this.totalQuestions[i].picked = [];
					}
				}
			});
		},
		async getAllModules() {
			console.log(this.$route.params.id);
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/modules/getAll`)
				.then(async (res: any) => {
					// this.spinnerLoading = false;
					// this.allModules = res.data;
					res.data.map((obj:any)=>{if(obj.isModuleActive == true && obj.screens){
						this.allModules.push(obj);
						this.allActiveModuleIds.push(obj._id);
					}})

				})
		},
			 handleBeforeUnload() {
      // Notify the parent window
      window.opener.postMessage('pageClosed', '*');
   		 },
	},

	
	beforeUnmount() {
    // Clean up the event listener when the component is about to be unmounted
   		 window.removeEventListener('beforeunload', this.handleBeforeUnload);
  		},

	async mounted() {
		// Listen for the 'beforeunload' event on Page B
		 window.addEventListener('beforeunload', this.handleBeforeUnload);
		await this.getAllModules();
		await this.getSingleCourse();
		await this.getAllQuestions();
	}
});
</script>
 